@use "../base/variable" as *;
@use "../base/theme_orange" as *;
@use "../base/icomoon-style";
@use "../base/mixin" as *;
@use "../base/keyframes";
.l-detailsWrap {
  display: flex;
  width: 1280px;
  max-width: 100%;
  margin: auto;
  padding: 0 20px;
  @include media (tab) {
    display: block;
  }
}
.l-contentsArea {
  width: 950px;
  max-width: 100%;
  @include media (sp) {
    width: 100%;
    margin: 10px auto;
    padding: 0;
  }
}
.productDetail {
  display: flex;
  padding-bottom: 60px;
  justify-content: space-around;
  padding-bottom: 110px;
  @include media(1160px) {
    justify-content: center;
    flex-wrap: wrap;
  }
  @include media (sp) {
    padding-bottom: 30px;
  }
  &__left {
    width: 500px;
    max-width: 100%;
    @include media(xl) {
      width: 380px;
    }
    @include media(sp) {
      width: 100%;
    }
  }
  &__mark {
    margin: 24px 0 40px;
    display: inline-block;
    padding: 20px 32px;
    border: 1px solid #e94e6f;
    border-radius: 15px;
    @include media(sp) {
      text-align: center;
      padding: 13px 20px;
    }
  }
  &__imgwrap {
    position: relative;
    width: 400px;
    height: 400px;
    border: 1px solid $bc_boder_gray_light;
    @include media(sp) {
      width: 100%;
      height: 300px;
      text-align: center;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      z-index: 1;
    }
    &-bg {
      height: 114px;
      width: 100%;
      background-color: $bc_white;
      position: absolute;
      top: 40%;
      left: 0;
      z-index: 0;
    }
  }
  &__info {
    width: 400px;
    max-width: 100%;
    margin-left: 50px;
    @include media(1160px) {
      width: 100%;
      margin: 24px 0 auto;
    }
    @include media(sp) {
      margin: 0;
    }
    &-title {
      line-height: 1;
      margin-top: 20px;
      @include media(sp) {
        display: block;
      }
    }
    &-brand {
      width: 100%;
      margin-bottom: 1em;
    }
    &-name {
      margin-bottom: .5em;
      font-size: 2.5rem;
      line-height: 1.4;
      @include media(sp) {
        margin: 0;
        font-size: 2.2rem;
      }
    }
    &-code {
      @include media(sp) {
        margin: 1.6rem 0 0 0.2rem;
      }
    }
    &-price {
      font-size: 2.8rem;
      line-height: 1;
      padding-bottom: .5em;
      margin: 40px 0;
      border-bottom: 1px solid $bc_boder_gray_light;
      letter-spacing: .1em;
      .only-sp{
        display: none;
      }
      @include media(tab){
        .only-sp{
          display: block;
        }
      }
      @include media(sp) {
        margin: 20px 0;
        font-size: 2.4rem;
      }
      .priceTxt {        
        p{
          display: inline-block;
        }
        .guideTxt{
          font-size: 1.4rem;
          margin-top: 1.4rem;
          color: #919191;
          display: block;
          span{
            margin-left: 0;
            font-size: 1.4rem;
            color: #ff000060;
          }
        }
        .base_price {
          font-size: 1.4rem;
          margin-left: 1.4rem;
          display: inline-block;
        }
        .title {
          margin-right: 1em;
        }
        .currency {
          margin-left: 0.2em;
        }
      }
      .red{
        color: red;
      }
      .sku_original_price{
        margin-top: 10px;
        font-size: 1.4rem;
        .price_01{          
         text-decoration: line-through;
         display: inline-block;
        }
        .base_price{
          text-decoration: line-through;
          margin-left: 0;
        }
      }
    }
    &-desc {
      border-top: 1px solid $bc_boder_gray_light;
      margin-bottom: 60px;
      @include media(tab){
        margin-bottom: 40px;
      }
      @include media(sp){
        margin-bottom: 30px;
      }
      .catchprase,
      h3 {
        padding: 60px 0 30px;
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
        line-height: 2;
        @include media(sp) {
          padding: 12px 0;
          font-size: 1.6rem;
        }
      }
      .descriptionTxt,
      p {
        padding-bottom: 60px;
        text-align: center;
      }
      table {
        width: 100%;
        border-top: 1px solid $bc_boder_gray_light;
        tr {
            border-bottom: 1px solid $bc_boder_gray_light;
          th,td {
            vertical-align: top;
            padding: 24px 0;
            @include media(sp) {
              display: block;
              padding: 12px 0;
            }
          }
          th {
            padding-right: 2.4rem;
            width: 10%;
            min-width: 8em;
            @include media(sp) {
              width: 100%;
              padding-bottom: 0;
            }
          }
          td {
            width: 70%;
            @include media(sp) {
              width: 100%;
            }
          }
        }
        ul li,
        ol li {
          text-indent: -1.2em;
          padding-left: 1.2em;
          &+li {
            margin-top: 1em;
          }
          &::before {
            content: "・";
          }
        }
        ol {
          counter-reset: heading-counter;
        }
        ol li {
          counter-increment: heading-counter;
          &::before {
            content: counter(heading-counter)"、";
          }
        }
      }
    }
  }
}
.productImages {
  width: 100%;
  margin: 0 auto;
  .slider-nav {
    // margin-right: -6px;
    // margin-top: 35px;
    // width: 400px;
    margin: 35px auto 0 auto;
    // @media (max-width: 500px){
    //   width: 350px;
    // }
    // @media (max-width: 400px){
    //   width: 280px;
    // }
    // @include media (425px) {
    //   margin-top: 10px;
    // }
    .slick-slide {
      margin-right: 8px;
      background-color: $c_base;
      border: 1px solid $c_border;
      width: 80px !important;
      height: 80px !important;
      position: relative;
      div{
        // position: absolute;
        // top: 0;
        // left: 0;
        // width: 100%;
        // height: 100%;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // padding: 5px;
        width: 100%;
        height: 100%;
        justify-content: center;
        display: flex !important;
        align-items: center;
        img{
          width: 100%;
          height: auto;
        }
      }

    }
    .slick-prev, .slick-next {
      top: 75%;
      transform: translate(0,-50%);
    }
    .slick-next {
      right: 0;
      @include media (425px) {
        right: 0;
      }
    }
    .slick-prev {
      @include media (425px) {
        left: -5px;
      }
    }
    .slick-prev:before {
      content: '\e902';
      display: inline-block;
    }
    .slick-next:before {
      content: '\e902';
      display: inline-block;
    }
  }
}
.selectBox {
  display: flex;
  align-items: center;
  margin: 0 10px 25px 0;
  @include media (sp) {
    font-size: 1.4rem;
  }
  @include media (425px) {
    margin: 0 0 20px 0;
  }
  &__outer {
    position: relative;
    &::before {
      content: '>';
      position: absolute;
      transform: rotate(90deg);
      top: 30%;
      right: 20px;
      z-index: 0;
      font-size: 1.8rem;
      line-height: 1;
      font-family: $f_base_all;
    }
    @include media (sp){
      width: 100%;
    }
  }
  &__lb {
    width: 7em;
  }
  &__opt {
    width: 220px;
    max-width: 100%;
    height: 40px;
    padding: 0 20px;
    border: 1px solid #ccc;
    border-radius: 100px;
    font-family: $f_base_all;
    appearance: none;
    position: relative;
    background: 0;
    text-align: left;
    @include media (sp) {
      width: 100%;
    }
  }
}
.l-cartInModal__body{
  .qty_and_cart{
    @include media(tab){
      display: flex;
      justify-content: space-between;
      align-items: center;
      .selectBox{
        width: 48%;
        margin: 0;
      }
      .cartIn-btn{
        margin: 0;
        width: 48%;
        .btns{
          margin-top: 0;
          .cartin{
            min-width: auto;
            height: 50px;
          }        
        }
      }
    }
    
  }
}

.cartIn-btn {
  margin-top: 60px;
  @include media (sp) {
    margin-top: 30px;
  }
  .cartin {
    position: relative;
    width: 100%;
    height: 60px;
    margin-right: 20px;
    color: $bc_white;
    background-color: $bc_primary;
    max-width: 322px;
    cursor: pointer;
    transition: .3s ease 0s;
    @include media(1160px){
      max-width: 100%;
    }
    @include media (sp) {
      min-width: 160px;
    }
    &:hover {
      opacity: .7;
    }
    &::after {
      position: absolute;
      top: 50%;
      right: 1em;
      content: '\e904';
      margin-right: 10px;
      font-family: 'icomoon-lc-casual';
      transform: translateY(-50%);
    }
    &--now {
      // background-color: transparent;
      // border: 1px solid $bc_boder_gray;
      // color: #000;
      &::after {
        //display: none;
      }
    }    
    &:disabled {
      cursor: not-allowed;
      background-color: #c2c2c2;
      &::after{
        display: none;
      }
      &:hover{
        opacity: 1;
      }
    } 
  }
  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    @include media(1160px){
      width: 90%;
    }
    @include media (sp) {
      margin-top: 10px;
      width: 85%;
    }
  }
}


.favorite {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 6rem;
  margin-left: 20px;
  cursor: pointer;
  transition: .3s ease 0s;
  color: $bc_text_gray;
  line-height: 1;
  &:hover {
    opacity: .7;
  }
  &.is-active {
    color: $bc_primary;
  }
}