@use "../base/variable" as *;
@use "../base/theme_orange" as *;
@use "../base/icomoon-style";
@use "../base/mixin" as *;;
@use "../base/keyframes";

.l-header {
    position: fixed;
    width: 100%;
    z-index: 10;
    top: 0;
    background: $bc_white;
  &__hamburgerbtn {
    width: 50px;
    height: 22px;
    top: 0;
    cursor: pointer;
    text-align: center;
    position: relative;
    margin-left: 5rem;
    .icon_txt01{
      position: absolute;
      top: 23px;
      font-size: 10px;
      color: #7C7C7C;
      right: 0;
    }
    @include media (tab) {
      margin-left: 1.4rem;  
    }
    span {
      position: absolute;
      width: 100%;
      height: 2px;
      right: 0;
      background-color: $bc_boder_gray;
      -webkit-transition: 0.5s all;
      -moz-transition   : 0.5s all;
      transition        : 0.5s all;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 13px;
        width: 35px;
      }
    }
    &.active span {
      &:nth-child(1) {
        top : 11px;
        left: 0;
        width: 50px;
        -webkit-transform: rotate(-20deg);
        -moz-transform   : rotate(-20deg);
        transform        : rotate(-20deg);
      }
      @-webkit-keyframes active-hamburger-bar02 {
        100% {
          height: 0;
        }
      }
      @keyframes active-hamburger-bar02 {
        100% {
          height: 0;
        }
      }
      &:nth-child(2) {
        top: 11px;
        width: 50px;
        -webkit-transform: rotate(20deg);
        -moz-transform   : rotate(20deg);
        transform        : rotate(20deg);
      }
    }
  }
  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    padding:10px 20px 10px 50px;
    @include media(tab) {
      padding: 5px 10px;
      min-height: initial;
      font-size: 1.4rem;
      line-height: 1.2;
    }
  }
  &__contact {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    padding-left: 2em;
    span {
      vertical-align: bottom;
      line-height: 2.3rem;
      @include media(tab) {
        display: none;
      }
    }
  }
  .icon-mail {
    margin-right: .3em;
    font-size: 2.3rem;
    vertical-align: bottom;
    line-height: 2.3rem;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 40px;
    background: $bc_white;
    @include media(tab) {
      padding: 20px 10px;
      border-bottom: solid 2px $bc_primary_light;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    img{
      width: 100%;
    }
    @include media(lg) {
      width: 250px;
    }
    @include media(tab) {
      width: 200px;
    }
  }
  &__iconsWrapper {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    .searchForm {
      display: none;
      width: 94%;
      margin: 8px auto;
      margin-left: 1.5em;
      @include media(tab) {
        display: none;
      }
    }
  }
  &__icons {
    display: flex;
    line-height: 1.4;
    align-items: center;
    @include media(tab) {
      // display: none;
      .active & {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 1em 5px;
        border-bottom: solid 1px $bc_primary;
      }
    }
    &.only-pc {
      display: flex;
      align-items: center;
      // @include media(tab) {
      //   display: none;
      // }
    }
    &.only-sp {
      display: none;
      @include media(tab) {
        &.active {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
  .snsIcons {
    position: relative;
    display: flex;
    padding-right: 1.2em;
    color: $bc_icon_gray;
    font-size: 2.8rem;
    @include media(lg) {
      display: none;
    }
    &__item {
      display: flex;
      align-items: center;
      text-align: left;
      justify-content: flex-start;
      padding: 12px 0;
      border-bottom: 1px solid #fff;
      font-size: 3.8rem;
      line-height: 1;
      max-width: 380px;
      @include media(lg){
        max-width: 100%;
      }
      &:last-child{
        border-bottom: none;
      }
      @include media(lg) {
        padding: 15px 0;
        margin-top: 5px;
      }
      .sns_link{
        display: flex;
        flex-wrap: wrap;
        margin-left: 10px;
        align-items: center;
        a{
          font-size: 1.3rem;
          width: 100%;
          margin: 5px 0;
        }
      }
    }
    &::after {
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      content: "";
      width: 4px;
      height: 100%;
      background: radial-gradient(circle farthest-side, $bc_icon_gray, $bc_icon_gray 50%, transparent 50%, transparent); 
      background-size: 4px 4px;
    }
  }
  .snsIcons.only-sp{
    display: none;
    @include media(lg){
      display: flex;
      width: 100%;
      padding: 0;
      margin: 0;
      align-items: center;
      justify-content: center;
      &:after{
        display: none;
      }
      .snsIcons__item{
        padding: 1.5rem;
      }
    }
  }
  .LCIcons {
    display: flex;
    color: $bc_icon_gray;
    font-size: 2.8rem;
    &.only-sp{
      display: none;
      @include media(sp){
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }
    }
    &__item {
      padding-left: 1em;
      text-align: center;
      position: relative;    
      .icon_txt{
        font-size: 10px;
        display: block;
        color: #7C7C7C;
      }
      .l-navi__badge{
        .js-lc--cartBadge{
          min-width: 20px;
          height: 20px;
          border-radius: 15px;
          color: #fff;
          font-size: 15px;
          font-weight: 700;
          position: absolute;
          right: -11px;
          top: -5px;
          display: flex;
          line-height: 1;
          // align-items: center;
          justify-content: center;
          background: #EF1F2E;
          padding: 1px 3px 0 3px;          
          @include media(sp){
            top: 2px;
          }
        }        
      }      
    }
    .icon-search {
      color: $bc_icon_gray;
    }
    .icon-triangle_down {
      margin-right: -.8em;
      padding-left: 0.2em;
      font-size: 0.3em;
      vertical-align: middle;
    }
  }
  .search__form{      
    width: 265px;
    position: relative;
    margin-right: 40px;
    &.search__form__head.only_pc{
      @include media(lg){
        display: none;
      }
    }
    input{
      width: 100%;
      padding: 0;
      border: none;
      border-bottom: 1px solid #BABABA;
      font-size: 14px;
      border-radius: 0;
      background: transparent;
      &:focus-visible{
        outline: 0;
      }
      &::-webkit-search-cancel-button,
      &::-webkit-search-decoration,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration{
        display: none;
      }
    }
    .icon-search {
      right: 0;
      left: auto;
      top: 22%;
      font-size: 20px;
      position: absolute;
      color: #807d7d;
      &.hide{
        display: none;
      }
    }
  }
}

 
.login__signup{
  width: 100%;
  max-width: 405px;
  font-size: 16px;
  color: $bc_primary;
  border: 1px solid $bc_primary;
  background: #fff;
  border-radius: 50px;
  display: inline-block;
  padding: 13px 25px;
  text-align: center;
  position: relative;
  @media screen and (min-width:850px){
    &.only_sp{
      display: none;
    }
  }
  @media screen and (max-width:849px){
    &.only_sp{
      display: block;
      margin: 0 auto;
    }
    &.only_pc{
      display: none;
    }
  }
  i{
    font-size: 20px;
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.bg_bc_primary_01{
  scrollbar-width: thin;
  scrollbar-color:#fff;
  @include media(tab){
    overflow-x: scroll;
  }
  &::-webkit-scrollbar{
    height: 3px;
  }
  &::-webkit-scrollbar-track{
      background-color: #fff;
  }
  &::-webkit-scrollbar-thumb{
      background-color: #ccc;
  }
  .w-extraWide{
    @include media(tab){
      min-width: 100%;
    }
    .l-navi {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      transition: all .6s;
      align-items: initial;
      // overflow-x: auto;
      // &.only_pc{
      //   @include media(tab){
      //     display: none;
      //   }
      // }
      // &.only_sp{
      //   display: none;
      //   @include media(tab){
      //     display: block;
      //   }
      // }
      &.pc_sp {
        top: 113px;
        position: fixed;
        visibility: hidden;
        opacity: 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100vh;
        padding-bottom: 200px;
        font-size: 1.6rem;
        color: $bc_primary;
        background-color: $bc_primary_light;
        overflow-y: auto;
        z-index: 100;
        @include media(tab){
          top: 95px;
        }
        @include media(sp){
          top: 81px;
        }
        &.active {
          visibility: visible;
          opacity: 1;
        }
        &.only_pc{
          .l-navi__item__pc{        
            display: none;
          }
        }
        .hamMenu{
          max-width: 1036px;
          margin: 70px auto 0 auto;
          @media screen and (max-width: 849px) {
            margin: 30px auto 0 auto;
          }
          width: 90%;
            .hamMenu__content__List{
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-wrap: wrap;
              .hamMenu__List{ 
                width: 50%;
                @media screen and (max-width:849px){
                  width: 100%;
                }        
                .top{
                  position: relative;
                  font-size: 2.2rem;
                  color: #7C7C7C;
                  padding-left: 165px;
                  margin-bottom: 55px;
                  overflow: hidden;
                  @media screen and (max-width: 849px) {
                    margin-bottom: 30px;
                    padding: 0;
                    padding-right: 20px;
                  }
                  &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 155px;
                    height: 1px;
                    background: #7C7C7C;
                    @media  screen and (max-width: 849px) {
                      display: none;
                    }
                  }
                  &::after{
                    content: '';
                    width: 100%;
                    height: 1px;
                    background: #7C7C7C;
                    position: absolute;
                    top: 50%;
                    margin-left: 20px;
                    display: none;
                    @media  screen and (max-width: 849px) {
                      display: inline-block;
                    }
                  }
                }
                &__Inner{
                  display: flex;
                  @media screen and (max-width: 849px) {
                    flex-wrap: wrap;
                  }
                  .hamMenu__List__Left,
                  .hamMenu__List__Right{
                    width: 50%;
                    display: flex;
                    flex-direction: column;  
                    min-width: 230px;
                    @media screen and (max-width: 849px) {
                      width: 100%;
                      min-width: auto;
                    }
                    a,
                    p{
                      font-size: 2.5rem;
                      color: #707070;
                      font-weight: bold;
                      margin-bottom: 30px;
                      display: inline-block;
                      position: relative;
                      span{
                        display: none;
                      }
                      &.ham_pad_cus{
                        margin-bottom: 10px;
                      }
                      @media screen and (max-width: 849px) {
                        font-size: 1.8rem;
                        display: block;
                        span{
                          display: inline-block;
                          position: absolute;
                          right: 0;
                          top: 50%;
                          transform: translateY(-50%);
                        }
                      }
                    }                    
                    .hamMenu__subList li a{
                      font-size: 2rem;
                      margin-bottom: 10px;
                    }
                  }
                }            
                .snsicons{
                  margin-top: 6.0rem;
                  @include media(lg){
                    margin-top: 0;
                  }
                  p{
                    font-size: 1.8rem;   
                    color: #909090;
                    margin-bottom: 25px;            
                  }
                  &__list{
                    display: flex;
                    a{
                      display: inline-block;
                      margin-right: 25px;
                      i{
                        font-size: 32px;
                        color: #807d7d;
                      }
                    }
                  }
                }
              }
              .hamMenu__List__Others{
                width: 50%;
                // max-width: 405px;
                text-align: center;
                @media screen and (max-width:849px){
                  width: 100%;
                } 
                .search__form{
                  width: 100%;
                  margin: 90px 0 70px 0;
                  input{
                    background: transparent;
                  }
                }
                .menu__list01{
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;
                  // max-width: 200px;
                  margin: 0 auto;
                  @media screen and (max-width: 849px) {
                    margin-top: 40px;
                  }
                  a{
                    display: inline-block;
                    font-size: 14px;
                    color: #7C7C7C;
                    &:last-child{
                      margin-right: 0;
                    }
                  }
                }
              }
            }
        }
      }
      &__item {
        position: relative;
        padding: 15px 0;
        line-height: 1.1;
        cursor: pointer;
        width: 25%;
        text-align: center;
        max-width: 200px;
        line-height: 1.3;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media(lg) {
          font-size: 13px;
        }
        @include media(tab) {
          text-align: center;
          padding: 10px 0;
        }
        a,p {
          display: inline-block;
          padding: 0 10%;
          border-bottom: solid 1px transparent;
          padding-bottom: 5px;
          position: relative;
          @include media(tab) {
              // padding: 0 8% 5px 10%;
              // padding: 0 10% 0 0;
              padding: 0;
              min-width: 90px;
          }
          // &.down_arrow{        
          //   &::after{
          //     content: "\e900";
          //     display: inline-block;
          //     font-family: "icomoon-lc-casual";
          //     font-size: .8em;
          //     position: absolute;
          //     right: 0;
          //     transform: translateY(-50%);
          //     top: 50%;
          //   }
          // }
        }
        &.down_arrow{        
          span{
            &::after{              
            content: "\e900";
            display: inline-block;
            font-family: "icomoon-lc-casual";
            font-size: .8em;
            }
          }
        }
        .submenu{
          position: absolute;
          display: none;
          top: 80%; 
          transform: translateX(-50%);
          left: 50%;
          width: 100%;
          min-width: 237px;
          text-align: left;
          z-index: 9;  
          padding-top: 30px;
          @media (max-width: 1448px) {
            min-width: 100%;
          }          
          @include media(tab){
            position: fixed;
            width: 95%;
            min-width: 95%;
            top: 104px;
          }  
          // @media (min-width: 641px) and (max-width: 768px){
          //   top: 18%;
          // }
          .subment_ul{
            border-radius: 15px;
            background: $bc_primary;        
            padding: 35px 30px;
            li{
              margin-bottom: 20px;
              a{
                display: inline-block;
                color: #fff;
                padding: 0;
                font-weight: normal;
                &:hover{
                  border-bottom: 1px solid #fff; 
                }
              }
              &:last-child{
                margin-bottom: 0;
              }
            }
          }
          
        }
        &:hover {      
          >a,>p {
            border-bottom: solid 1px #fff;
            opacity: 1;
            @include media(tab) {
              border-bottom: 0;
            }
          }
        }
        &.is-current{
          background: #f8f5ec;
          color: #000;
          font-weight: bold;
          &:hover{
            >a,>p{
              border-bottom: 1px solid transparent;
            }
          }
        }
        &:hover{      
          .submenu {
            display: block;
          }
        }
        &.sidebarItem {
          display: none;
          @include media(tab) {
            display: block;
          }
        }
      }
      .search__form{  
        @media screen and (min-width:850px){
          &.only_sp{
            display: none;
          }
        }
        @media screen and (max-width:849px){
          &.only_sp{
            display: block;
            width: 100%;
            max-width: 405px;
            margin: 30px auto 30px auto;
          }
          &.only_pc{
            display: none;
          }
        }
      }
    }
  }
  

}




// .searchForm{
//   width: 90%;
//   margin: auto;
//   position: relative;
//   &__input[type=search] {
//     width: 100%;
//     height: 40px;
//     border: solid 1px #ccc;
//     border-radius: 10px;
//     padding-left: 52px;
//     @include media (sp) {
//       width: 100%;
//       margin-bottom: 10px;
//     }
//     &::placeholder {
//       color: #B4B4B4;
//       font-family: YuMincho;
//     }
//   }
//   &__button {
//     width: 24px;
//     height: 24px;
//     position: absolute;
//     font-size: 2.4rem;
//     top: 22%;
//     left: 16px;
//     color: $bc_icon_gray;
//     @include media (sp) {
//       top: 16%;
//       left: 3%;
//     }
//   }
// }

.no-scroll{
  overflow-y: hidden;
}
 
